export default {
  async getDiscountMorePet(ctx) {
    try {
      if (ctx.$store.state.discountMorePets) {
        return ctx.$store.state.discountMorePets;
      }
      const res = await ctx.$axios.get('/discounts/more-pets/next-discount');
      ctx.$store.commit('set_discountMorePets', res.data);
      return res.data;
    } catch (error) {
      ctx.$nuxt?.$honeybadger?.notify(error);
      return error;
    }
  }
};
