
import FourPetsSvg from '@/assets/images/home/4pets.svg';
import ExtrasSvg from '@/assets/images/home/extras.svg';
import GiftSvg from '@/assets/images/home/gift.svg';
import AuthService from '@/services/auth';
import DiscountMorePetsService from '@/services/discountMorePetsService';
import { navigateToCheckout } from '@/utils/navigation';

const authService = new AuthService();

export default {
  components: {
    FourPetsSvg,
    GiftSvg,
    ExtrasSvg
  },
  data() {
    return {
      discountMorePets: undefined,
      isLogged: false
    };
  },
  computed: {
    showDiscountMorePets() {
      return this.discountMorePets && !this.isLogged;
    }
  },
  async mounted() {
    this.isLogged = authService.isLogged();
    if (this.isLogged) {
      return;
    }
    await this.getDiscountMorePets();
  },
  methods: {
    toContrate() {
      location.href = navigateToCheckout({
        params: {
          from: 'discountsAndAdvantages',
          ...this.$route.query
        }
      });
    },
    async getDiscountMorePets() {
      try {
        const data = await DiscountMorePetsService.getDiscountMorePet(this);
        this.discountMorePets = data.current_discounts;
      } catch (error) {
        this.discountMorePets = undefined;
      }
    }
  }
};
